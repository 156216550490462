<template>
    <div>
        <!-- start page title -->
        <div v-if="title" class="row mt-4 mb-3">
            <div class="col-12">
                <div class="page-title-box" >
                    <h4 class="page-title mb-0 pb-0" v-html="title"></h4>
                </div>
            </div>
        </div>
        <div v-else class="mt-4"></div>
        <!-- end page title -->
        <router-view></router-view>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'ProjectLayout',
    computed: {
        ...mapState({
			meta: (state) => state.navigation.meta
        }),
        title() {
            if(this.meta.title) return this.meta.title
        }
    }
};
</script>

<style>
.description { 
    font-size: 13px;
}
.description p {
    margin-bottom: 0.5rem;
}
.page-title {
    line-height: 1rem !important;
}
</style>